import React, { useEffect, useState } from "react";
import SpriteLoader from "../Utils/SpriteLoader";


import title from '../assets/img/title.png';
import title_webp from '../assets/img/title.webp';

export const LoaderPage = ({setLoaded}) => {

    const [percent, setPercent] = useState(0);

    useEffect(() => {
        SpriteLoader.load(step => {
            setPercent(step);

            if (step > 1.975) {
                setTimeout(() => setLoaded(true), 500);
            }
        })
    }, [])

    return (
        <div className="loader">
            <picture>
                <source srcSet={title_webp} type="image/webp" />
                <img src={title} alt="En 2022 Métaversez-vous !" />
            </picture>
            <div className="loader_box">
                <div className="loader_box_item loader_box_roue1"></div>
                <div className="loader_box_item loader_box_roue2"></div>
                <div className="loader_box_item loader_box_roue3"></div>
                <div className="loader_box_item loader_box_jauge"></div>
                <div className="loader_box_item loader_box_fleche" style={{ transform: `rotate(${80 - percent * 40}deg)` }}></div>
            </div>
        </div>
    )
};