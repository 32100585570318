import React, {useRef} from "react";

// Libraries
// Import Swiper React components
import SwiperCore, {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Navigation]);

const Carrousel = ({avatar, currentChoice, setCurrentChoice, choice, setChoice, isValidable, scale}) => {

    const swiperRef = useRef(null);
    const hostURL = process.env.REACT_APP_URL;

    const validateChoice = () => {
        let index = swiperRef.current.swiper.activeIndex - 1
        console.log(index);
        if (swiperRef.current.swiper.activeIndex - 1 < 0) {
            index = 9
        }
        if (swiperRef.current.swiper.activeIndex - 1 > 9) {
            index = 0
        }
        setChoice({...choice, [currentChoice.value]: index})//on enregistre le choix
    };

    const resetChoices = () => {
        setChoice({
            hat: '',
            eyes: '',
            nose: '',
            mouth: '',
            accessories: ''
        })
    };

    const handleRandom = () => {
        setChoice({
            hat: Math.floor(Math.random() * avatar['hat'].choices.length),
            eyes: Math.floor(Math.random() * avatar['eyes'].choices.length),
            nose: Math.floor(Math.random() * avatar['nose'].choices.length),
            mouth: Math.floor(Math.random() * avatar['mouth'].choices.length),
            accessories: Math.floor(Math.random() * avatar['accessories'].choices.length)
        });

        setCurrentChoice({id: 5, value: 'Accessories'})
    };


    return (
        <div className="c-carrousel"
             style={{transform: `scale(${scale})`, top: `calc(800px*${scale})`, left: `calc(780px*${scale})`}}>
            <div className="c-carrousel_extras">
                <button className="c-button c-button_reset border-- inline-- blueLight--"
                        onClick={() => handleRandom()}>
                    <i className="icon-random"/>
                </button>
                <button className="c-button c-button_reset border-- inline-- orange-- animateReset--"
                        onClick={() => resetChoices()}>
                    <i className="icon-reset"/>
                </button>
            </div>
            <div className={`c-carrousel_container ${isValidable && "disabled--" }`}>
                <Swiper className="c-carrousel_swiper"
                        spaceBetween={0}
                        loop={true}
                        speed={500}
                        slidesPerView={1}
                        grabCursor={true}
                        ref={swiperRef}
                >
                    {avatar[currentChoice.value] && !isValidable &&
                    avatar[currentChoice.value].choices.map(({visuelSlider}, key) => {
                        return (
                            <SwiperSlide key={key}>
                                {visuelSlider &&
                                <div className="c-carrousel_choice">
                                    <img className="c-carrousel_visuel" src={hostURL + visuelSlider} alt=""/>
                                </div>
                                }
                            </SwiperSlide>
                        )
                    })
                    }
                </Swiper>
                <button
                    className={`c-button c-carrousel_prev border-- small-- ${!isValidable ? "azure-- animatePrec--" : "disabled--" }`}
                    onClick={() => swiperRef.current.swiper.slidePrev()}><i className="icon-prev"/></button>
                <button
                    className={`c-button c-carrousel_next border-- small-- ${!isValidable ? "purple-- animateNext--" : "disabled--" }`}
                    onClick={() => swiperRef.current.swiper.slideNext()}><i className="icon-next"/></button>
                <button
                    className={`c-button c-button_validate c-carrousel_validate border-- rectangle-- ${!isValidable ? "green-- animateValidate--" : "disabled--" }`}
                    onClick={() => validateChoice()}><i className="icon-check"/></button>

            </div>
        </div>
    )
};

export default Carrousel;