import React, {useEffect, useRef} from "react";
import {useSelector} from 'react-redux';

// Components
import CLink from "./link";


import logo from '../assets/img/logo-hf.png';


// LIBRARIES
// GA
import ReactGA from "react-ga4";

const Canvas = ({avatarRef, avatarCanvasRef, choicesUrl, setImageBase64, reset, scale}) => {

    const hostURL = process.env.REACT_APP_URL;

    const canvasRef = useRef(null);
    const canvasRefShare = useRef(null);
    const linkRef = useRef(null);

    let {ga} = useSelector(state => state.global);

    // Breakpoint
    const {desktop} = useSelector(state => state.breakpoint);

    let compteur_loading = 0;

    const render = () => {
        if (canvasRef.current && canvasRefShare.current) {
            let context = canvasRef.current.getContext("2d");
            let context_share = canvasRefShare.current.getContext("2d");
            canvasRef.current.width += 0;
            canvasRefShare.current.width += 0;

            choicesUrl
                .filter(item => item.img && item.img.ready)
                .forEach((item, key) => {
                    if (key !== 0) {
                        // context.drawImage(item.img, 0, 0, 500, 750);
                        context.drawImage(item.img, 125, 0, 500, 750);
                        context_share.drawImage(item.img, 482, 256, 232, 348);
                    }
                    else {
                        context_share.drawImage(item.img, 0, 0, 1200, 630);
                    }

                });

            let image = canvasRef.current.toDataURL("image/png");
            // let image_share = canvasRefShare.current.toDataURL("image/png");

            setImageBase64(image);
            if (linkRef.current) {
                linkRef.current.setAttribute('download', 'avatar.png');
                linkRef.current.setAttribute('href', image);
            }
        }
    };

    useEffect(() => {
        const updateLoading = (item) => {
            item.img.ready = true;
            compteur_loading++;

            if (compteur_loading === choicesUrl.length) {
                render();
            }
        }

        choicesUrl.forEach(item => {
            item.img = new Image();
            item.img.ready = false;
            item.img.onload = () => {
                updateLoading(item);
            };
            item.img.onerror = () => {
                console.log("error");
                updateLoading(item);
            };
            item.img.src = item.url
        });
    }, [choicesUrl]);


    return (
        <React.Fragment>
            <div ref={avatarRef} className="c-revelateur_canvas"
                 style={desktop ? {transform: `scale(calc(${scale}))`} : {}}>
                <canvas ref={avatarCanvasRef} className="c-canvas" ref={canvasRef} width={750} height={750}/>
                <canvas className="c-canvas_disabled" ref={canvasRefShare} width={1200} height={630}/>
                <div className="c-canvas_bottom">
                    <div className="c-button_list">
                        <div className="c-button_items">
                            <a href="#" className="c-button rectangleLarge-- degrade--" ref={linkRef}
                               onClick={() => {
                                   if (ga === true) {
                                       ReactGA.event({
                                           category: "Bouton",
                                           action: "telecharger",
                                           label: "download", // optional
                                       })
                                   }
                               }}><span>Télécharger l'avatar</span></a>
                        </div>
                        {/* {idAvatar && */}
                        <div className="c-button_items">
                            <button className="c-button carreLarge-- degrade--" onClick={() => {
                                reset();
                                if (ga === true) {
                                    ReactGA.event({
                                        category: "Bouton",
                                        action: "clic reset",
                                        label: "restart", // optional
                                    });
                                }
                            }}><i className="icon-reload"/></button>

                            <CLink url={`https://www.facebook.com/sharer/sharer.php?u=${hostURL}`}
                                   className="c-button carreLarge-- degrade--" external={true} target="_blank"
                                   handleClick={() => {
                                       if (ga === true) {
                                           ReactGA.event({
                                               category: "Bouton",
                                               action: "partage",
                                               label: "facebook", // optional
                                           })
                                       }
                                   }}><i
                                className="icon-facebook"/></CLink>
                            <CLink
                                url={`https://twitter.com/intent/tweet?url=${hostURL}&amp;text=Metaversez&nbsp;vous&nbsp;!`}
                                className="c-button carreLarge-- degrade--" external={true} target="_blank"
                                handleClick={() => {
                                    if (ga === true) {
                                        ReactGA.event({
                                            category: "Bouton",
                                            action: "partage",
                                            label: "twitter", // optional
                                        })
                                    }
                                }}><i
                                className="icon-twitter"/></CLink>
                        </div>
                        {/* } */}
                    </div>
                </div>
            </div>
            <img src={logo} className="c-revelateur_logo" alt="Havas Factory" style={desktop ? {
                transform: `scale(calc(${scale}))`,
                bottom: `calc(35px*${scale})`,
                right: `calc(50px*${scale})`
            } : {}}/>
        </React.Fragment>
    )
};

export default Canvas;
