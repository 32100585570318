import React, {useEffect} from "react";

// Hooks
import {useDispatch} from 'react-redux';
import {updatePage} from '../Store/action';
// import { useLocation } from 'react-router-dom';
// import useApi from '../Hooks/useApi';

// Components
import ComponentByJSON from '../Layout/flexibles/component-from-json';
import Metatags from '../Layout/metas-tags/meta-tags';

const TemplateContentPage = () => { // { _uid }

    let dataFetch = require('../api/page.json');
    // const location = useLocation();
    // const [dataFetch, isLoaded] = useApi({name: 'content-page', slug: location.pathname, _uid});
    const {metas, flexibles_content} = dataFetch;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatePage('content-page'));
    }, []); // eslint-disable-line

    return (
        <>
            <Metatags {...metas} />

            <div className="container">
                <br/>
                <br/>
                <br/>
                Page de contenu
                <br/>
                <br/>
                <br/>
                <br/>
            </div>

            {flexibles_content && flexibles_content.map((block) => ComponentByJSON(block))}

        </>
    )
};

export default TemplateContentPage;