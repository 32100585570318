import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';

// Hooks
import { updatePage } from '../Store/action';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import Carrousel from "../Components/carrousel";
import Sprites from "../Components/sprites";
import Machine, { MachineSides } from "../Components/machine";
import Revelateur from "../Components/revelateur";
import Intro from "../Components/intro";

// Librarires
import ReactGA from "react-ga4";

// Images
import logo from "../assets/img/logo-hf.png";

const TemplateHome = () => {
    const hostURL = process.env.REACT_APP_URL;

    //Affichage des differentes parties
    const [intro, setIntro] = useState("showIntro--");
    const [machine, setMachine] = useState("showMachine--");
    const [revelateur, setRevelateur] = useState("hideRevelateur--");

    // Breakpoint
    const { mobile } = useSelector(state => state.breakpoint);

    //recuperation des infos de la page
    let dataFetch = require('../api/page.json');
    const [metaData, setMetaData] = useState({ dataFetch });

    //Récuperation des infos de l'avatar
    let dataAvatar = require('../api/avatar.json');
    const { avatar, base } = dataAvatar;

    const [positionMachine, setPositionMachine] = useState({});
    const [spritesScale, setSpritesScale] = useState(1);

    const [choice, setChoice] = useState({
        hat: '',
        eyes: '',
        nose: '',
        mouth: '',
        accessories: ''
    });

    const [choicesUrl, setChoicesUrl] = useState([]);
    const [currentChoice, setCurrentChoice] = useState({ id: 0, value: 'hat' });
    const [isValidable, setIsValidable] = useState(false);
    const [imageBase64, setImageBase64] = useState('');


    const [handlePlayPause, setHandlePlayPause] = useState(false);
    const [handlePlayPause2, setHandlePlayPause2] = useState(false);
    const videoRef = useRef(null);
    const bgdVideoRef = useRef(null);
    const avatarRef = useRef(null);

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatePage('home'));
        window.addEventListener("resize", () => resize());
        resize();
    }, []); // eslint-disable-line

    const supprChoice = (category) => {
        setChoice({ ...choice, [category]: '' })//on enregistre le choix
    };

    useEffect(() => {
        if (machine !== "hideMachine--" && intro !== "hideIntro--") {
            const timer = setTimeout(() => {
                setIntro("fadeOutIntro--")
                clearTimeout(timer);
            }, 100);

            const timer2 = setTimeout(() => {
                setIntro("hideIntro--")
                clearTimeout(timer2);
            }, 100);
        }
    }, [machine]); // eslint-disable-line

    const reset = () => {
        setMachine('showMachine--');
        setRevelateur("hideRevelateur--")
        setChoice({
            hat: '',
            eyes: '',
            nose: '',
            mouth: '',
            accessories: ''
        })
        setChoicesUrl([]);
        setCurrentChoice({ id: 0, value: 'hat' });
        setIsValidable(false);
        setImageBase64('');
        setHandlePlayPause(false);
        setHandlePlayPause(false);
        window.isSend = false;
        videoRef.current.style.display = "block";
        videoRef.current.style.opacity = "1";
        bgdVideoRef.current.style.opacity = 0;
        avatarRef.current.style.opacity = 0;
    }
    let { ga } = useSelector(state => state.global);
    const validateAvatar = () => {
        if (isValidable) {
            document.body.style.cursor = "wait";
            var baseChoice = Math.floor(Math.random() * base.choices.length);
            setRevelateur('showRevelateur--');
            setHandlePlayPause(true);
            setHandlePlayPause2(true)
            if (ga === true) {
                ReactGA.event({
                    category: "Bouton",
                    action: "clic valider",
                    label: "validated", // optional
                });
            }

            //on recupere la liste des images selectionnées
            setChoicesUrl([{
                "url": hostURL + '/assets/bgd-share.jpg'
            },
            {
                "url": hostURL + avatar.accessories.choices[choice.accessories].visuelFront
            },
            {
                "url": hostURL + avatar.hat.choices[choice.hat].visuelBack,
            },
            {
                "url": hostURL + base.choices[baseChoice].visuelFront
            },
            {
                "url": hostURL + avatar.hat.choices[choice.hat].visuelFront
            },
            {
                "url": hostURL + avatar.eyes.choices[choice.eyes].visuelFront
            },
            {
                "url": hostURL + avatar.mouth.choices[choice.mouth].visuelFront
            },
            {
                "url": hostURL + avatar.nose.choices[choice.nose].visuelFront
            }])
        }
    };

    useEffect(() => {
        const nextChoice = () => {
            var BreakException = {};
            try {
                Object.keys(choice).forEach((element, value) => {
                    if (choice[element] === '') {
                        setCurrentChoice({ id: value, value: element });
                        throw BreakException;
                    }
                });
            } catch (e) {
                if (e !== BreakException) throw e;
            }
        };
        nextChoice();

        if (choice.hat !== '' && choice.eyes !== '' && choice.nose !== '' && choice.mouth !== '' && choice.accessories !== '') {
            setIsValidable(true);
            setCurrentChoice({ id: 5, value: 'Accessories' })
        }
        else {
            setIsValidable(false)
        }
    }, [choice]);

    useEffect(() => {
        if (imageBase64 !== '') {
            if (!window.isSend) {
                let formData = new FormData();
                formData.append('image', imageBase64);
                handleSubmitAvatar(formData)
                window.isSend = true;
            }
        }
    }, [imageBase64]); // eslint-disable-line

    //Positionnement de la machine
    let MACHINE_WIDTH = 1920;
    let MACHINE_HEIGHT = 1080;
    let MACHINE_RATIO = MACHINE_WIDTH / MACHINE_HEIGHT;

    const resize = () => {
        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_ratio = window_width / window_height;

        let positions = {};

        if (window_ratio > MACHINE_RATIO) {
            let width_new = MACHINE_WIDTH / MACHINE_HEIGHT * window_height;
            positions.height = window_height + "px";
            positions.top = "0px";
            positions.left = 0.5 * (window_width - width_new) + "px";
            positions.width = width_new + "px";
            setSpritesScale(window_height / MACHINE_HEIGHT);
        } else {
            let height_new = MACHINE_HEIGHT / MACHINE_WIDTH * window_width;

            if (mobile) {
                height_new = (window_height - (window_height / window.innerWidth * 100 / 2));
                positions.width = height_new * MACHINE_WIDTH / MACHINE_HEIGHT + "px";
                positions.top = "25px";
                positions.left = -1 * (height_new * MACHINE_WIDTH / MACHINE_HEIGHT - window_width) / 2 + "px";
                setSpritesScale(height_new / MACHINE_HEIGHT);
            } else {
                positions.width = window_width + "px";
                positions.top = 0.5 * (window_height - height_new) + "px";
                positions.left = "0px";
                setSpritesScale(window_width / MACHINE_WIDTH);
            }
            positions.height = height_new + "px";


        }

        setPositionMachine(positions);
    };

    const handleSubmitAvatar = (data) => {
        Axios({
            headers: {
                "partner-key": "1160c23e9274116850c7f0a8d6c8a3d9",
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*"
            },
            method: 'post',
            url: `${process.env.REACT_APP_APP}/api/avatar/create`,
            data: data
        });
    }

    const [nft, setNft] = useState(0);
    const [meta, setMeta] = useState(0);
    const [bitcoin, setBitcoin] = useState(0);

    const videoPlayerRef = useRef(null);

    return (
        <>
            {metaData && <Metatags {...metaData.dataFetch.metas} />}
            <div className={`content ${(machine !== "hideMachine--") ? "bgd-" + currentChoice.id : ''}`}>
                {
                    intro !== "hideIntro--" &&
                    <Intro intro={intro} />
                }
                <div className="c-machine">
                    <div className={`c-machine_content ${intro && intro} ${machine && machine}`}
                        style={{ ...positionMachine }}>
                        {intro !== "hideIntro--" &&
                            <div className="c-machine_blur" />
                        }
                        {machine !== 'hideMachine--' &&
                            <>
                                <Machine />
                                <Sprites nft={nft} meta={meta} bitcoin={bitcoin} />
                                <div className="c-buttons-sprite">
                                    <button type="button" className="c-buttons-sprite_item nft" style={{
                                        transform: `scale(${spritesScale})`,
                                        top: `calc(218px*${spritesScale})`,
                                        left: `calc(964px*${spritesScale})`
                                    }} onClick={() => setNft(nft + 1)} />
                                    <button type="button" className="c-buttons-sprite_item meta" style={{
                                        transform: `scale(${spritesScale})`,
                                        top: `calc(528px*${spritesScale})`,
                                        left: `calc(740px*${spritesScale})`
                                    }} onClick={() => setMeta(meta + 1)} />
                                    <button type="button" className="c-buttons-sprite_item bitcoin" style={{
                                        transform: `scale(${spritesScale})`,
                                        top: `calc(445px*${spritesScale})`,
                                        left: `calc(1150px*${spritesScale})`
                                    }} onClick={() => setBitcoin(bitcoin + 1)} />
                                </div>
                                <MachineSides />
                                {avatar &&
                                    <Carrousel avatar={avatar} currentChoice={currentChoice}
                                        setCurrentChoice={setCurrentChoice}
                                        choice={choice} setChoice={setChoice} isValidable={isValidable}
                                        scale={spritesScale} />
                                }

                                <div className="c-list" style={{
                                    transform: `scale(${spritesScale}) translateX(-50%)`,
                                    top: `calc(980px*${spritesScale})`
                                }}>
                                    <button className={`c-button choices-- ${choice.hat !== '' && 'enabled--'}`}
                                        onClick={() => choice.hat !== "" ? supprChoice('hat') : ''}>
                                        <div className="c-button_deleteBgd" />
                                        {choice.hat !== '' ? (avatar.hat &&
                                            <img src={hostURL + avatar.hat.choices[choice.hat].visuelSlider}
                                                alt="" />) :
                                            <i className="icon-hat" />}
                                        <div className="c-button_delete"><i className="i-cross" /></div>
                                    </button>
                                    <button className={`c-button choices-- ${choice.eyes !== '' && 'enabled--'}`}
                                        onClick={() => choice.eyes !== "" ? supprChoice('eyes') : ''}>
                                        <div className="c-button_deleteBgd" />
                                        {choice.eyes !== '' ? (avatar.eyes &&
                                            <img src={hostURL + avatar.eyes.choices[choice.eyes].visuelSlider}
                                                alt="" />) :
                                            <i className="icon-eyes" />}
                                        <div className="c-button_delete"><i className="i-cross" /></div>
                                    </button>
                                    <button className={`c-button choices-- ${choice.nose !== '' && 'enabled--'}`}
                                        onClick={() => choice.nose !== "" ? supprChoice('nose') : ''}>
                                        <div className="c-button_deleteBgd" />
                                        {choice.nose !== '' ? (avatar.nose &&
                                            <img src={hostURL + avatar.nose.choices[choice.nose].visuelSlider}
                                                alt="" />) :
                                            <i className="icon-nose" />}
                                        <div className="c-button_delete"><i className="i-cross" /></div>
                                    </button>
                                    <button className={`c-button choices-- ${choice.mouth !== '' && 'enabled--'}`}
                                        onClick={() => choice.mouth !== "" ? supprChoice('mouth') : ''}>
                                        <div className="c-button_deleteBgd" />
                                        {choice.mouth !== '' ? (avatar.mouth &&
                                            <img src={hostURL + avatar.mouth.choices[choice.mouth].visuelSlider}
                                                alt="" />) :
                                            <i className="icon-mouth" />}
                                        <div className="c-button_delete"><i className="i-cross" /></div>
                                    </button>
                                    <button
                                        className={`c-button choices-- ${choice.accessories !== '' && 'enabled--'}`}
                                        onClick={() => choice.accessories !== "" ? supprChoice('accessories') : ''}>
                                        <div className="c-button_deleteBgd" />
                                        {choice.accessories !== '' ? (avatar.accessories &&
                                            <img
                                                src={hostURL + avatar.accessories.choices[choice.accessories].visuelSlider}
                                                alt="" />) : <i className="icon-accessories" />}
                                        <div className="c-button_delete"><i className="i-cross" /></div>
                                    </button>
                                    <button
                                        className={`c-button border-- large-- ${isValidable ? "green--" : "disabled--"}`}
                                        onClick={() => validateAvatar()}><i className="icon-check" /></button>
                                </div>
                            </>
                        }
                    </div>
                </div>
                {(intro === "hideIntro--" && revelateur === 'hideRevelateur--') &&
                    <img src={logo} className="c-machine_logo" alt="Havas Factory" />}
                <Revelateur avatarRef={avatarRef} bgdVideoRef={bgdVideoRef} videoRef={videoRef} handlePlayPause={handlePlayPause} handlePlayPause2={handlePlayPause2} videoPlayerRef={videoPlayerRef} revelateur={revelateur} setHandlePlayPause={setHandlePlayPause} setMachine={setMachine} choicesUrl={choicesUrl}
                    setImageBase64={setImageBase64} reset={reset} scale={spritesScale} />

            </div>
        </>
    )
};

export default TemplateHome;