import React, {useEffect} from "react";
import {useDispatch} from 'react-redux';

// Hooks
import {updatePage} from '../Store/action';

const Template404 = () => {
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatePage('page-404'));
    }, []); // eslint-disable-line

    return (
        <div className="container">
            404
        </div>
    )
};

export default Template404;