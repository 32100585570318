import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';

// Hooks
import { updatePage } from '../Store/action';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import JaoIntro from "../Components/jao/jao-intro";

const TemplateHome = () => {

    //recuperation des infos de la page
    let dataFetch = require('../api/page.json');
    const [metaData, setMetaData] = useState({ dataFetch });

    //Récuperation des infos de l'avatar
    let dataAvatar = require('../api/avatarbingo2.json');
    const { avatar, base } = dataAvatar;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatePage('home'));
    }, []); // eslint-disable-line

    return (
        <>
            {metaData && <Metatags {...metaData.dataFetch.metas} />}
            <div className={`content bgd-0`}>
                <JaoIntro avatar={avatar} base={base} />
                {/* <JaoReveal /> */}
            </div>
        </>
    )
};

export default TemplateHome;