import React from 'react';

// import FText from '../../Flexibles/text';
// import FTextImg from '../../Flexibles/text-img';
// import FVideo from "../../Flexibles/video";
// import FImg from "../../Flexibles/img";
// import FSlider from '../../Flexibles/slider';

/**
 * Déclaration de la correspondance nom reçu / nom du composant
 *
 */
const DynamicComponents = {
    /*   bloc_wysiwyg: FText,
       bloc_texte_image: FTextImg,
       bloc_video: FVideo,
       bloc_carrousel: FSlider,
       bloc_image: FImg,*/
};

/**
 * ## Generation des composants dynamiquement suivant JSON
 * @param {object} block
 * cf : https://www.storyblok.com/tp/react-dynamic-component-from-json
 */
const ComponentByJSON = (block, index) => {
    if (typeof DynamicComponents[block.bloc] !== 'undefined') {
        return React.createElement(DynamicComponents[block.bloc], {
            key: block._uid,
            index: index,
            ...block.datas
        });
    }
    // component doesn't exist yet
    return React.createElement(() => <div className="container"><p>{block.bloc} : Not yet or wrong
                                                                                name!</p></div>, {key: block._uid});
};
export default ComponentByJSON;