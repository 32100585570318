import React from "react";
import {Link} from "react-router-dom";

const CLink = ({children, className, url, title, target, external = false, handleClick}) => {
    return (
        <>
            {(!external) ?
                <Link to={url} title={title} target={target} className={className}
                      onClick={handleClick}>{children ? children : title}</Link>
                :
                <a href={url} title={title} target={target} className={className}
                   onClick={handleClick}>{children ? children : title}</a>
            }
        </>
    )
};

export default CLink;