import React from "react";
import ReactPlayer from 'react-player'
import SpriteLoader from "../Utils/SpriteLoader";

const VideoRevelateur = React.memo(function VideoRevelateur({ handlePlayPause, videoPlayerRef, fadeOut, hideVideo, setMachine }) {
    return (
        <>
            <ReactPlayer
                ref={videoPlayerRef}
                className='c-revelateur_videoContent'
                playing={handlePlayPause}
                url={[{ src: URL.createObjectURL(SpriteLoader.blob), type: 'video/mp4' }]}
                width='100%'
                height='100%'
                muted={true}
                playsinline={true}
                progressInterval={500}
                onReady={() => {
                    videoPlayerRef.current.wrapper.style.opacity = 1;
                    document.body.style.cursor = "default";
                }}
                onProgress={(played) => (
                    (played.played >= 0.83) &&
                    fadeOut.current()
                )}
                onEnded={() => {
                    hideVideo.current()
                    setMachine("hideMachine--")
                }}
            />
        </>
    )
});

export default VideoRevelateur;
