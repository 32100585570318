import React from "react";

// Libraries
import {SpriteManager, SpriteSheet} from "./spriteManager";

const Sprites = ({nft, meta, bitcoin}) => {
    return (

        <div className="c-sprites__list">
            <SpriteManager>
                <SpriteSheet
                    name={'ventilo'}
                    top={90}
                    left={1134}
                    fps={60}
                />
                <SpriteSheet
                    name={'ball1'}
                    top={112}
                    left={1185}
                    fps={30}
                />
                <SpriteSheet
                    name={'verre'}
                    top={400}
                    left={1098}
                    fps={60}
                />
                <SpriteSheet
                    name={'vis'}
                    top={398}
                    left={1210}
                    fps={30}
                />
                <SpriteSheet
                    name={'ball2'}
                    top={266}
                    left={1012}
                    fps={30}
                />
                <SpriteSheet
                    name={'roue'}
                    top={136}
                    left={329}
                    fps={20}
                />
                <SpriteSheet
                    name={'manette'}
                    top={525}
                    left={357}
                    fps={30}
                />
                <SpriteSheet
                    name={'barber'}
                    top={523}
                    left={592}
                    fps={30}
                />
                <SpriteSheet
                    name={'cerveau'}
                    top={186}
                    left={736}
                    fps={60}
                />
                <SpriteSheet
                    name={'clavier'}
                    top={503}
                    left={1444}
                    fps={60}
                />
                <SpriteSheet
                    name={'phosphore'}
                    top={131}
                    left={1006}
                    fps={30}
                />
                <SpriteSheet
                    name={'oiseau'}
                    top={280}
                    left={988}
                    fps={30}
                />
                <SpriteSheet
                    name={'four'}
                    top={-24}
                    left={713}
                    fps={30}
                />
                <SpriteSheet
                    name={'manche'}
                    top={112}
                    left={476}
                    fps={30}
                />
                <SpriteSheet
                    name={'moteur'}
                    top={40}
                    left={458}
                    fps={45}
                />
                <SpriteSheet
                    name={'patate'}
                    top={238}
                    left={397}
                    fps={30}
                />
                <SpriteSheet
                    name={'meta'}
                    top={490}
                    left={575}
                    fps={30}
                    fusion={"screen"}
                    clickable={"meta"}
                    visible={meta}
                />
                <SpriteSheet
                    name={'nft'}
                    top={93}
                    left={877}
                    fps={30}
                    fusion={"lighten"}
                    clickable={"nft"}
                    visible={nft}
                />
                <SpriteSheet
                    name={'bitcoin'}
                    top={290}
                    left={1130}
                    fps={30}
                    fusion={"lighten"}
                    clickable={"bitcoin"}
                    visible={bitcoin}
                />
                <SpriteSheet
                    name={'electricite'}
                    top={35}
                    left={885}
                    fps={30}
                    fusion={"lighten"}
                />

            </SpriteManager>
        </div>


    )
};

export default Sprites;