class SpriteLoader {

    static load(callback) {
        this.callback = callback;

        if (!this.alreadyDone) {
            this.alreadyDone = true;

            let isMobile = window.innerWidth < 1024;

            this.loadVideo(isMobile, () => {
                this.spriteSheets = this.getInitialSpriteSheets().filter(sprite => !isMobile || sprite.runMobile);

                this.spriteSheets_total = this.spriteSheets.length;
                this.spriteSheets_compteur = 0;

                this.loadImage();
            })
        } else {
            this.callback(1);
        }
    }

    static loadVideo(isMobile, callback2) {
        let url = "./assets/video/video-revelation" + (isMobile ? "-mobile" : "") + ".mp4";

        fetch(url)
            .then(data => data.blob())
            .then(blob => {
                SpriteLoader.blob = blob;
                callback2();
            })
    }

    static loadImage() {
        let sheet = this.spriteSheets[this.spriteSheets_compteur];

        sheet.img = new Image();
        sheet.img.onload = () => this.updateSheet(sheet, false);
        sheet.img.onerror = () => this.updateSheet(sheet, true);
        sheet.img.src = sheet.url;
    }

    static updateSheet(spritesheet, isErreur) {
        spritesheet.erreur = isErreur;

        this.spriteSheets_compteur++;
        this.callback(this.spriteSheets_compteur / this.spriteSheets_total);

        if (this.spriteSheets_compteur >= this.spriteSheets_total) {
            this.generateImage(0);
        } else {
            setTimeout(() => this.loadImage(), 10);
        }
    }

    static generateImage(index) {

        if (index < this.spriteSheets.length) {
            let spritesheet = this.spriteSheets[index];
            let { cols, lines, width, height, img, erreur } = spritesheet;

            if (!erreur) {
                let ua = navigator.userAgent;

                var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                var isIOS = ua.includes("iPhone") || ua.includes("iPod") || ua.includes("iPad");

                if (true && (isIOS || isSafari)) {
                    spritesheet.canvasMore = img;
                } else {
                    spritesheet.canvasMore = document.createElement("canvas");
                    spritesheet.canvasMore.width = img.width;
                    spritesheet.canvasMore.height = img.height;
                    let context = spritesheet.canvasMore.getContext("2d");
                    context.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width, img.height);
                }


                spritesheet.sprites = [];
                let nbTotal = cols * lines;

                for (let i = 0; i < nbTotal; i++) {
                    let x = i % cols;
                    let y = Math.floor(i / cols);

                    // let canvas = document.createElement("canvas");
                    // canvas.width = width;
                    // canvas.height = height;
                    // let context = canvas.getContext("2d");
                    // context.drawImage(img, x * width, y * height, width, height, 0, 0, width, height);
                    spritesheet.sprites.push([x * width, y * height, width, height]);
                }
            }

            this.spriteSheets_compteur++;
            this.callback(this.spriteSheets_compteur / this.spriteSheets_total);

            setTimeout(() => this.generateImage(++index), 10);
        }
    }

    static getSpriteSheet(name) {
        let sheet = this.spriteSheets.filter(sheet => sheet.name === name);
        if (sheet.length > 0) {
            return sheet[0]
        } else {
            return null;
        }
    }

    static getInitialSpriteSheets() {
        return [
            // {
            //     name: "ventilo",
            //     url: "./assets/sprites_20220104/sprite_ventilo.png",
            //     width: 184,
            //     height: 186,
            //     cols: 15,
            //     lines: 12,
            //     runMobile: false
            // },
            {
                name: "ball1",
                url: "./assets/sprites_20220104/sprite_ball1.png",
                width: 415,
                height: 260,
                cols: 9,
                lines: 10,
                runMobile: false
            },
            {
                name: "verre",
                url: "./assets/sprites_20220104/sprite_verre.png",
                width: 350,
                height: 420,
                cols: 15,
                lines: 12,
                runMobile: true
            },
            {
                name: "vis",
                url: "./assets/sprites_20220104/sprite_vis.png",
                width: 390,
                height: 140,
                cols: 2,
                lines: 5,
                runMobile: false
            },
            {
                name: "ball2",
                url: "./assets/sprites_20220104/sprite_ball2.png",
                width: 480,
                height: 327,
                cols: 6,
                lines: 15,
                runMobile: true
            },
            {
                name: "roue",
                url: "./assets/sprites_20220104/sprite_roue.png",
                width: 331,
                height: 442,
                cols: 12,
                lines: 10,
                runMobile: false
            },
            {
                name: "manette",
                url: "./assets/sprites_20220104/sprite_manette.png",
                width: 320,
                height: 200,
                cols: 6,
                lines: 15,
                runMobile: false
            },
            {
                name: "barber",
                url: "./assets/sprites_20220104/sprite_barber.png",
                width: 70,
                height: 240,
                cols: 13,
                lines: 2,
                runMobile: true
            },
            {
                name: "cerveau",
                url: "./assets/sprites_20220104/sprite_cerveau.png",
                width: 234,
                height: 111,
                cols: 9,
                lines: 20,
                runMobile: true
            },
            {
                name: "clavier",
                url: "./assets/sprites_20220104/sprite_clavier.png",
                width: 180,
                height: 320,
                cols: 15,
                lines: 6,
                runMobile: false
            },
            {
                name: "phosphore",
                url: "./assets/sprites_20220104/sprite_phosphore.png",
                width: 220,
                height: 406,
                cols: 15,
                lines: 6,
                runMobile: true
            },
            {
                name: "oiseau",
                url: "./assets/sprites_20220104/sprite_oiseau.png",
                width: 345,
                height: 260,
                cols: 12,
                lines: 15,
                runMobile: false
            },
            {
                name: "four",
                url: "./assets/sprites_20220104/sprite_four.png",
                width: 340,
                height: 580,
                cols: 10,
                lines: 9,
                runMobile: true
            },
            {
                name: "manche",
                url: "./assets/sprites_20220104/sprite_manche.png",
                width: 330,
                height: 180,
                cols: 5,
                lines: 6,
                runMobile: false
            },
            {
                name: "moteur",
                url: "./assets/sprites_20220104/sprite_moteur.png",
                width: 350,
                height: 530,
                cols: 10,
                lines: 9,
                runMobile: true
            },
            {
                name: "patate",
                url: "./assets/sprites_20220104/sprite_patate.png",
                width: 166,
                height: 301,
                cols: 18,
                lines: 10,
                runMobile: false
            },
            {
                name: "meta",
                url: "./assets/sprites_20220104/sprite_meta.png",
                width: 331,
                height: 303,
                cols: 8,
                lines: 11,
                runMobile: true
            },
            {
                name: "nft",
                url: "./assets/sprites_20220104/sprite_nft.png",
                width: 220,
                height: 163,
                cols: 6,
                lines: 15,
                runMobile: true
            },
            {
                name: "bitcoin",
                url: "./assets/sprites_20220104/sprite_bitcoin.png",
                width: 111,
                height: 201,
                cols: 15,
                lines: 6,
                runMobile: true
            },
            {
                name: "electricite",
                url: "./assets/sprites_20220104/sprite_electricite.png",
                width: 179,
                height: 134,
                cols: 9,
                lines: 10,
                runMobile: true
            }
        ]
    }
}

export default SpriteLoader;