import './Styles/App.scss';
import React, {useEffect, useState} from 'react';
import ScrollToTop from './Components/ScrollToTop';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import RouteForTemplates from './Layout/routes/route-for-templates';
import {LoaderPage} from './Components/loader';

// HOOKS
import {useSelector, useDispatch} from 'react-redux';
import {
    updateGlobalStoreGlobal,
    updateGlobalStoreRoutes,
    updateGlobalScrollLock,
    updateIsMobile,
    updateIsDesktop,
    updateGA
} from './Store/action';

// LIBRARIES
// GA
import ReactGA from "react-ga4";

const App = () => {
    let dispatch = useDispatch();

    const [isLoaded, setLoaded] = useState(false)

    // API dynamiques ou via des JSON
    let dataGlobalAPI = require('./api/global.json');
    let dataRoutesAPI = require('./api/route.json');

    let page = useSelector(state => state.page).page;

    let {mobile, desktop} = useSelector(state => state.breakpoint);

    // INIT SCROLL LOCK REDUCER // DISPATCH LANGUAGE FROM URL
    useEffect(() => {
        dispatch(updateGlobalScrollLock(false));
    }, []); // eslint-disable-line

    // STORE ADD GLOBAL API
    useEffect(() => {
        dispatch(updateGlobalStoreGlobal(dataGlobalAPI));
    }, [dataGlobalAPI]); // eslint-disable-line

    // STORE ADD ROUTES API
    useEffect(() => {
        dispatch(updateGlobalStoreRoutes(dataRoutesAPI));
    }, [dataRoutesAPI]); // eslint-disable-line


    // DISPATCH WINDOW SIZE ON RESIZE
    useEffect(() => {
        const checkDevice = () => {
            if (window.matchMedia('(max-width:1023px)').matches && !mobile) {
                dispatch(updateIsMobile(true));
                dispatch(updateIsDesktop(false));
            }
            if (window.matchMedia('(min-width:1024px)').matches && !desktop) {
                dispatch(updateIsMobile(false));
                dispatch(updateIsDesktop(true))
            }
        };

        checkDevice();

        window.addEventListener('resize', checkDevice);

        // clean listener
        return () => {
            window.removeEventListener('resize', checkDevice);
        }
    }, [mobile, desktop]);// eslint-disable-line


    useEffect(() => {
        if (dataGlobalAPI && dataRoutesAPI) {
            // window.prerenderReady = true;
        }
    }, [dataGlobalAPI, dataRoutesAPI]); // eslint-disable-line


    useEffect(() => {
        let init = true;
        let init2 = true;
        if (window.tarteaucitron) {
            let timer = setInterval(() => { // eslint-disable-line

                if (window.tarteaucitron.state['gtag'] === true && ga === false && init === true) {
                    dispatch(updateGA(true));
                    init = false;
                } else if (window.tarteaucitron.state['gtag'] === false && ga === true && init2 === true) {
                    dispatch(updateGA(false));
                    init2 = false;
                }
            }, 1000);
        }
    }, []); // eslint-disable-line


    const {ga} = useSelector(state => state.global);
    const [hasInit, setHasInit] = useState(false);
    useEffect(() => {
        if (ga === true && dataGlobalAPI && hasInit === false) {
            ReactGA.initialize('UA-156905791-2');
            ReactGA.ga('send', 'pageview', window.location.pathname + window.location.searc);
            setHasInit(true);
        }
    }, [ga, dataGlobalAPI, hasInit]); // eslint-disable-line


    return (
        <div className={`app ${page}`}>
            <LoaderPage setLoaded={setLoaded}/>

            <Router>
                {
                    isLoaded && <ScrollToTop>
                        <Switch>
                            {dataRoutesAPI && (
                                dataRoutesAPI.map((route) => {
                                    return <RouteForTemplates key={route._uid} {...route} datas={{}}/>;
                                })
                            )}
                        </Switch>
                    </ScrollToTop>
                }
            </Router>
        </div>
    );
};

export default App;