import React, { useRef, useState, useEffect } from "react";
import VideoRevelateur from "./videoRevelateur";
import VideoCanvas from "./videoCanvas";
import Canvas from "./canvas";

const Revelateur = ({ avatarRef, bgdVideoRef, handlePlayPause, handlePlayPause2, setHandlePlayPause, videoPlayerRef, videoRef, revelateur, setMachine, choicesUrl, setImageBase64, reset, scale }) => {

    const contentRef = useRef(null);
    const avatarCanvasRef = useRef(null);
    const bgdRef = useRef(null);

    const fadeOutHandler = () => {
        videoRef.current.style.opacity = 0;
        videoRef.current.style.background = "none";
        contentRef.current.style.opacity = 1;
        avatarRef.current.style.opacity = 1;
        bgdRef.current.style.background = "black";
        // avatarCanvasRef.current.style.transform = "scale(1)";
        contentRef.current.style.clipPath = "circle(100%)";
        contentRef.current.style.webkitClipPath = "circle(100%)";
    }

    const fadeOut = useRef(() => fadeOutHandler())

    const hideVideoHandler = () => {
        videoRef.current.style.display = "none";
        bgdVideoRef.current.style.opacity = 1;
        bgdRef.current.style.background = "transparent";
        videoPlayerRef.current.seekTo(0);
        setHandlePlayPause(false);
        setMachine('hideMachine--');
    }

    const hideVideo = useRef(() => hideVideoHandler())

    const [videoRender, setVideoRender] = useState(false);
    useEffect(() => {
        setVideoRender(true);
        // videoRef.current.playing = true;
    }, [])

    return (
        <div className={`c-revelateur ${revelateur ? revelateur : ''}`} ref={bgdRef}>
            <div className="c-revelateur_video" ref={videoRef}>
                <VideoRevelateur handlePlayPause={handlePlayPause} videoPlayerRef={videoPlayerRef} fadeOut={fadeOut} hideVideo={hideVideo} setMachine={setMachine} />
            </div>
            <div className="c-revelateur_content" ref={contentRef}>
                <div className="c-revelateur_player" ref={bgdVideoRef}>
                    <VideoCanvas handlePlayPause2={handlePlayPause2} />
                </div>

                <Canvas avatarRef={avatarRef} avatarCanvasRef={avatarCanvasRef} choicesUrl={choicesUrl} setImageBase64={setImageBase64} reset={reset} scale={scale} />
            </div>
        </div>

    )
};

export default Revelateur;
