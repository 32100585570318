import React from "react";

// import title from '../assets/img/title.png';
// import title_webp from '../assets/img/title.webp';
const Intro = ({ intro }) => {

    return (
        <div className={`c-intro ${intro}`}>
            {/* <div className="c-intro_metaversez">
                <picture>
                    <source srcSet={title_webp} type="image/webp" />
                    <img src={title} alt="Havas Factory" />
                </picture>
            </div> */}
        </div>
    )
};


export default Intro;