import React from "react";

//import machine from '../assets/img/machine-bgd.png';
import machineBackFils from '../assets/img/machine-bgd-back-fils.png';
import machineBack from '../assets/img/machine-bgd-back-full.png';
import machineBackVentilo from '../assets/img/machine-bgd-back-ventilo.png';
import machineFront from '../assets/img/machine-bgd-front-full.png';
import machineFrontLeft from '../assets/img/machine-bgd-front-full-left2.png';
import machineFrontLeft_webp from '../assets/img/machine-bgd-front-full-left2.webp';
import machineFrontRight from '../assets/img/machine-bgd-front-full-right2.png';
import machineFrontRight_webp from '../assets/img/machine-bgd-front-full-right2.webp';
import machineFrontRightFils from '../assets/img/machine-bgd-front-full-right2-fils.png';
import machineShadow from '../assets/img/machine-bgd-shadow.png';


const Machine = () => {
    return (
        <>
            <div className="c-machineBgd">
                <img src={machineShadow} alt=""/>
                <img src={machineFrontRightFils} className="c-machineBgd_rightfils" alt=""/>
                <img src={machineBackFils} className="c-machineBgd_fils" alt=""/>
                <img src={machineBack} alt=""/>
                <img src={machineBackVentilo} className="c-machineBgd_ventilo" alt=""/>
                {/*<img src={machine}/>*/}
            </div>
        </>
    )
};

export default Machine;


export const MachineSides = () => {
    return (
        <>
            <div className="c-machineBgd">
                <picture>
                    <source srcSet={machineFrontLeft_webp} type="image/webp"/>
                    <source srcSet={machineFrontLeft} type="image/png"/>
                    <img src={machineFrontLeft} alt="" className="c-machineBgd_reverse c-machineBgd_reverse--left"/>
                </picture>
                <img src={machineFront} alt=""/>
                <picture>
                    <source srcSet={machineFrontRight_webp} type="image/webp"/>
                    <source srcSet={machineFrontRight} type="image/png"/>
                    <img src={machineFrontRight} alt="" className="c-machineBgd_reverse c-machineBgd_reverse--right"/>
                </picture>
            </div>
        </>
    )
};