import React, { useEffect, useRef } from "react";
import SpriteLoader from "../Utils/SpriteLoader";

export const SpriteManager = ({ children }) => {

    const canvasRef = useRef(null);
    const requestRef = useRef(null);

    const WIDTH_CANVAS = 1920;
    const HEIGHT_CANVAS = 1080;

    let sprites = children.map(child => ({ ...child.props }));

    const refValue = useRef(sprites);
    // const isMobile = useRef(window.innerWidth < 1024);

    const updateVisible = (name) => {
        let currentSprite = refValue.current.filter(sprite => sprite.clickable === name);
        if (currentSprite.length > 0) {
            currentSprite = currentSprite[0];
            currentSprite.timer = 0;
            currentSprite.needReset = true;
            currentSprite.running = true;
        }
    }

    useEffect(() => {

        const renderCanvas = (t) => {
            if (canvasRef.current) {
                let canvas = canvasRef.current;
                let context = canvas.getContext("2d");
                canvas.width += 0;

                refValue.current.forEach(child => {
                    let { name, left, top, fps, fusion, clickable, needReset, running } = child;

                    let spriteSheet = SpriteLoader.getSpriteSheet(name);

                    if (spriteSheet) {
                        let { cols, lines, sprites, canvasMore } = spriteSheet;

                        if (needReset) {
                            child.timer = t;
                            child.needReset = false;
                            child.currentIndex = -1;
                        }

                        let timerDecalage = running ? child.timer : 0;

                        let nextCurrentIndex = Math.floor((t - timerDecalage) / (1000 / fps)) % (cols * lines);

                        if (child.running && nextCurrentIndex < child.currentIndex) {
                            child.running = false;
                        }

                        child.currentIndex = nextCurrentIndex;

                        if (sprites && sprites[child.currentIndex]) {
                            if (typeof (clickable) === "undefined" || child.running) {
                                let spr = sprites[child.currentIndex];

                                context.globalCompositeOperation = fusion ? fusion : "source-over";
                                context.drawImage(canvasMore, spr[0], spr[1], spr[2], spr[3], left, top, spr[2], spr[3]);
                            }
                        }
                    }
                })
            }

            requestRef.current = requestAnimationFrame(renderCanvas);
        }

        requestRef.current = requestAnimationFrame(renderCanvas);

        return () => cancelAnimationFrame(requestRef.current);
    }, []);

    return (
        <>
            {children.map((child, index) => <SpriteSheet key={`sprite_sheet_${index}`} {...child.props}
                onVisibleChange={updateVisible} />)}
            <canvas ref={canvasRef} width={WIDTH_CANVAS} height={HEIGHT_CANVAS} />
        </>
    )
};

export const SpriteSheet = ({ visible, clickable, onVisibleChange }) => {

    useEffect(() => {
        if (typeof (visible) !== "undefined" && visible > 0) {
            onVisibleChange(clickable);
        }
    }, [visible]);

    return <></>
};
