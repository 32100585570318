import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/img/logo-hf.png";

const JaoIntro = ({ avatar }) => {

    const backRef = useRef(null);
    const inputRef = useRef(null);
    const linkRef = useRef(null);
    const [isValidable, setIsValidable] = useState(false);
    const [codeEntered, setCodeEntered] = useState(false);

    const validateCode = () => {
        if (isValidable && inputRef.current) {
            loadAvatar(inputRef.current.value.toLowerCase());
            setCodeEntered(true);
        }
    }

    const checkAvatar = () => {
        if (inputRef.current) {
            setIsValidable(avatar.indexOf(inputRef.current.value.toLowerCase()) > -1)
        }
    }

    const loadAvatar = (code) => {
        if (backRef.current && linkRef.current) {
            let img = new Image();
            img.width = 1000;
            img.height = 1500;
            img.onload = () => {
                backRef.current.appendChild(img);
                linkRef.current.href = "/assets/bingo/" + code + ".png";
            }
            img.src = "/assets/bingo/" + code + ".png";
        }
    }

    return (
        <div className={`jaoIntro ${codeEntered ? "jaoIntro--validated" : "jaoIntro--unvalidated"}`}>
            <div className="jaoIntro_box">
                <p className="jaoIntro_titre">Tentez de gagner 1000€ !<br />Une chance sur 2.5 millions</p>
                <div className={`jaoIntro_cardList`}>
                    <div className="jaoIntro_cardItemBox"><div className="jaoIntro_cardItem"></div></div>
                    <div className="jaoIntro_cardItemBox"><div className="jaoIntro_cardItem"></div></div>
                    <div className="jaoIntro_cardItemBox">
                        <div className="jaoIntro_cardItem">
                            <div className="jaoIntro_cardItem_front"></div>
                            <div className="jaoIntro_cardItem_back" ref={backRef}></div>
                        </div>
                    </div>
                    <div className="jaoIntro_cardItemBox"><div className="jaoIntro_cardItem"></div></div>
                    <div className="jaoIntro_cardItemBox"><div className="jaoIntro_cardItem"></div></div>
                </div>
                <div className="jaoIntro_bottom">
                    <p className="jaoIntro_texte">Je tente ma chance</p>
                    <div className="jaoIntro_form">
                        <div className="c-button rectangleLarge-- degrade-- noHover jaoIntro_formInput">
                            <input type="text" placeholder="Le code ici" ref={inputRef} onKeyUp={checkAvatar} />
                        </div>
                        <button
                            className={`c-button border-- large-- ${isValidable ? "green--" : "disabled--"}`}
                            onClick={() => validateCode()}><i className="icon-check" /></button>
                    </div>
                </div>
            </div>
            <div className="jaoIntro_fin">
                <p className="jaoIntro_fin_titre jaoIntro_fin_msg1">Perdu ...<br />
                    Tu t’y attendais un peu non ?</p>
                <p className="jaoIntro_fin_texte jaoIntro_fin_texte--mobile jaoIntro_fin_msg2">Mais tu as plus de chances de trouver un stage,<br />
                    alternance ou job en envoyant ton CV à :</p>
                <p className="jaoIntro_fin_texte jaoIntro_fin_msg3"><a href="mailto:jevousrejoins@havas.com">jevousrejoins@havas.com</a></p>
                <p className="jaoIntro_fin_cta jaoIntro_fin_msg4"><a ref={linkRef} href="#" download="avatar.png" className="c-button rectangleLarge-- degrade--"><span>Télécharge ton avatar</span></a></p>
                <p className="jaoIntro_fin_cta jaoIntro_fin_msg5"><Link to="/" className="c-button rectangleLarge-- degrade--"><span>Test notre metamachine !</span></Link></p>
                <div className="jaoIntro_fin_logo jaoIntro_fin_msg6"><img src={logo} alt="Havas Factory" /></div>
            </div>
        </div>
    )
};

export default JaoIntro;