import React from "react";
import ReactPlayer from 'react-player'
import {useSelector} from 'react-redux';


import videoReveal from '../assets/video/reveal.mp4';
import videoRevealMobile from '../assets/video/reveal-mobile.mp4';
const VideoRevelateur = ({handlePlayPause2}) => {
    const {mobile} = useSelector(state => state.breakpoint);
    return (
        <ReactPlayer
            playing={handlePlayPause2}
            url={
                mobile ? 
                    [{src: videoRevealMobile, type: 'video/mp4'}]
                :
                    [{src: videoReveal, type: 'video/mp4'}]
            }
            width='100%'
            height='100%'
            muted={true}
            loop={true}
            playsinline={true}
        />
    )
};

export default VideoRevelateur;
